import { concat, Observable, of } from 'rxjs'
import { catchError, last, mergeMap, tap } from 'rxjs/operators'
import HeaderStoreAdapter from '../adapter/ShoppingCartHeaderStoreAdapter'
import { FacadeApiClientInterface } from '../api-service/FacadeApiClient'
import { ShoppingCartHeaderApiResultInterface } from '../model/api-result/ShoppingCartHeaderApiResultInterface'
import { PatchHeaderParamsInterface } from '../model/PatchHeaderParamsInterface'
import { ShoppingCartStoreInterface } from '../state-manager/ShoppingCartStore'

export interface CouponManagerServiceInterface {
  applyCoupon$(coupon: string): Observable<ShoppingCartHeaderApiResultInterface>
  removeCoupon$(coupon: string): Observable<ShoppingCartHeaderApiResultInterface>
}

export class CouponManager {
  constructor(private scApiClient: FacadeApiClientInterface, private rootStore: ShoppingCartStoreInterface) {}
  private patchCoupon$(params: any) {
    return concat(
      this.scApiClient.patchHeaders$(params).pipe(catchError(() => this.scApiClient.getHeader$())),
      this.scApiClient.getHeader$()
    ).pipe(
      last(),
      tap((header: ShoppingCartHeaderApiResultInterface) => {
        this.rootStore.setHeader(HeaderStoreAdapter(header))
        this.rootStore.setLastCoupon(header.couponCode || '')
      }),
      mergeMap((header) => {
        return of(header)
      })
    )
  }
  applyCoupon$(coupon: string) {
    const params: PatchHeaderParamsInterface = {
      applyCouponCode: coupon
    }
    return this.patchCoupon$(params)
  }
  removeCoupon$(coupon: string) {
    const params: PatchHeaderParamsInterface = {
      removeCouponCode: coupon
    }
    return this.patchCoupon$(params)
  }
}

export default CouponManager
