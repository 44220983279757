import amplitude, { AmplitudeClient } from 'amplitude-js'
import { getSnapshot } from 'mobx-state-tree'
import { AMPLITUDE_KEY } from '../../app/environment/const'
import { StorageWrapperInterface } from '../../app/service/storage/StorageWrapper'
import {
  ADDRESS_TYPE_SHIPPING,
  IPLABS_EDITOR_LABEL,
  LAST_PAYMENT_VENDOR_KEY,
  ORDER_TYPE,
  PHOTOSI_EDITOR_LABEL,
  PLATFORM_TYPE,
  VERSION_WEB
} from '../environment/const'
import { AmplitudePaymentsMethodAcceptedTypes } from '../model/AmplitudePaymentMethodAcceptedTypes'
import { PaymentsMethodAcceptedTypes } from '../model/PaymentMethodAcceptedTypes'
import { ProductStoreInterface, ProductStoreInterfaceSnapshotOut } from '../state-manager/ProductStore'
import { ShoppingCartStoreInterface } from '../state-manager/ShoppingCartStore'

export interface AmplitudeEventProduct {
  name: string
  id: string
  price: number
  quantity: number
  productType: string
}

export interface AmplitudeEventInterface {
  initClient(platform: string): void
  cartAddSuccess(): void
  cartAddError(): void
  promoCodeError(err: string): void
  cartView(): void
  deliverytypesView(): void
  deliverytypesChooseClick(): void
  deliverytypesAutoSelect(): void
  orderConfirmationStart(): void
  summaryView(): void
  promoCodeSuccess(code: string): void
  promoCodeRemove(code: string): void
  paymentSuccess(): void
  paymentSelect(paymentCode: PaymentsMethodAcceptedTypes): void
  paymentError(err: string): void
  paymentStart(): void
  userPurchase(): void
  gotoMapFromPayment(): void
  addressAddClick(): void
  addressFormSave(): void
  addressFormSearch(): void
  addressFormView(): void
  addressListDeleteClick(): void
  confirmDeleteAddress(): void
  addressListEditClick(): void
  addressListView(): void
  editOrderAddressTap(): void
  promoCodeInfoClick({ promoCodeId, reason, reasonText }: { promoCodeId: string; reason: string; reasonText: string }): void
  deliveryTypesInfoClick({ type, info, message }: { type: string; info: string; message: string }): void
  confirmOrderError({ error }: { error: string }): void
  orderDeleteClick(): void
}

export type errorPromocodeInteraction = 'coupon code not found' | 'coupon not appliable'

export class AbstractAmplitudeEvent {
  constructor(protected rootStore: ShoppingCartStoreInterface) {}

  private productAdapter(product: ProductStoreInterfaceSnapshotOut): AmplitudeEventProduct {
    return {
      name: product.productCode,
      id: product.id,
      price: product.unitPrice || 0,
      productType: product.productType,
      quantity: product.quantity || 0
    }
  }

  protected getAllProducts(): Array<AmplitudeEventProduct> {
    const products: ProductStoreInterfaceSnapshotOut[] = getSnapshot(this.rootStore.products)
    return products.map((product: ProductStoreInterfaceSnapshotOut) => this.productAdapter(product))
  }

  protected getProduct(productId: string): AmplitudeClient[] {
    const products: AmplitudeEventProduct[] = this.getAllProducts()
    //@ts-ignore
    return products.filter((item: AmplitudeEventProduct) => {
      if (item.id === productId) {
        return item
      }
    })
  }
}

export class AmplitudeEvent implements AmplitudeEventInterface {
  private client = amplitude.getInstance()
  private loggingEnabled = false

  constructor(private rootStore: ShoppingCartStoreInterface, private storage: StorageWrapperInterface) {}

  initClient(platform: string) {
    this.client.init(
      AMPLITUDE_KEY,
      undefined,
      {
        saveEvents: true,
        includeUtm: true,
        includeReferrer: true,
        trackingOptions: {
          ip_address: false
        }
      },
      (client) => {
        this.loggingEnabled = true
        const orderType = this.rootStore.productsHeader.isIpLabsFlow ? IPLABS_EDITOR_LABEL : PHOTOSI_EDITOR_LABEL
        const identify = new client.Identify().set(`${PLATFORM_TYPE}`, platform).set(`${ORDER_TYPE}`, orderType)
        client.identify(identify)
        client.setUserId(this.rootStore.userInfo.id)
      }
    )
  }

  protected getPaymentEventName(paymentCode: PaymentsMethodAcceptedTypes): AmplitudePaymentsMethodAcceptedTypes {
    const paymentMethodMap: Record<PaymentsMethodAcceptedTypes, AmplitudePaymentsMethodAcceptedTypes> = {
      paypal: 'paypal',
      'credit-card': 'credit_card',
      'apple-pay-stripe': 'apple_pay',
      'google-pay-stripe': 'google_pay',
      offline: 'offline',
      'in-shop': 'in_shop',
      'cash-on-delivery': 'cash_on_delivery',
      scalapay: 'scalapay',
      klarna: 'klarna'
    }

    return paymentMethodMap[paymentCode] || 'unknown_payment_method'
  }

  logEvent(event: string, data?: unknown) {
    if (!this.loggingEnabled) return
    this.client.logEvent(event, data)
  }
  cartAddSuccess(): void {
    if (!(this.rootStore.createUserToken === true)) return
    this.logEvent('cart_add_success', {
      userID: this.rootStore.userInfo.id,
      userType: this.rootStore.userType,
      unirepo_product_code: this.rootStore.products.reduce((acc: string, product: ProductStoreInterfaceSnapshotOut) => {
        return product.productCode
      }, ''),
      productCategoryCode: this.rootStore.products.reduce((acc: string, product: ProductStoreInterfaceSnapshotOut) => {
        return product.productCategoryCode
      }, ''),
      orderId: this.rootStore.header.id,
      version: VERSION_WEB,
      withvalue: this.rootStore.header.vatIncludedFullTotal,
      currencyCode: this.rootStore.header.currencyCode,
      quantity: this.rootStore.products.reduce((acc: number, current: ProductStoreInterfaceSnapshotOut) => {
        return (acc += current.quantity)
      }, 0)
    })
  }

  cartAddError(): void {
    this.logEvent('cart_add_error', {
      errorCodeId: this.rootStore.getErrorMessage(),
      userID: this.rootStore.userInfo.id
    })
  }

  cartView(): void {
    this.logEvent('cart_view', {
      userID: this.rootStore.userInfo.id
    })
  }

  orderConfirmationStart(): void {
    this.logEvent('order_confirmation_start', {
      orderId: this.rootStore.header.id
    })
  }

  deliverytypesView(): void {
    this.logEvent('deliverytypes_view', {
      userID: this.rootStore.userInfo.id
    })
  }

  deliverytypesChooseClick(): void {
    this.logEvent('deliverytypes_choose_click', {
      methodShipping: this.rootStore.shippingMethod.code
    })
  }

  deliverytypesAutoSelect(): void {
    this.logEvent('deliverytypes_auto_select', {
      methodShipping: this.rootStore.shippingMethod.code
    })
  }

  summaryView(): void {
    this.logEvent('summary_View', {
      userID: this.rootStore.userInfo.id
    })
  }

  promoCodeSuccess(code: string): void {
    this.logEvent('promo_code_success', {
      promocode_ID: code
    })
  }

  promoCodeRemove(code: string): void {
    this.logEvent('promo_code_remove', {
      promocode_ID: code
    })
  }

  promoCodeError(err: string): void {
    let errorLabel = `coupon error: ${err}`
    if (errorLabel.indexOf('40') !== -1) {
      errorLabel = 'coupon code not found'
    } else if (errorLabel.indexOf('50') !== -1) {
      errorLabel = 'coupon not appliable'
    }

    this.logEvent('promo_code_error', {
      promoCodeError_ID: errorLabel,
      promocode_ID: this.rootStore.header.couponCode
    })
  }
  paymentStart(): void {
    this.logEvent('payment_start', {
      userID: this.rootStore.userInfo.id
    })
  }

  paymentSelect(paymentCode: PaymentsMethodAcceptedTypes): void {
    this.logEvent('payment_select', {
      paymentype_ID: this.getPaymentEventName(paymentCode)
    })
  }

  paymentError(err: string): void {
    let paymentErrorLabel = `payment_error: ${err}`
    if (paymentErrorLabel.indexOf('paypal') !== -1) {
      paymentErrorLabel = 'paypal payment error'
    } else if (paymentErrorLabel.indexOf('credit') !== -1) {
      paymentErrorLabel = 'credit-card payment error'
    }
    this.logEvent('payment_error', {
      paymenterror_ID: paymentErrorLabel,
      paymentype_ID: this.getPaymentEventName(this.rootStore.paymentMethod.code)
    })
  }

  paymentSuccess(): void {
    const paymentCode = this.storage.load(LAST_PAYMENT_VENDOR_KEY) as PaymentsMethodAcceptedTypes
    this.logEvent('payment_success', {
      paymentype_ID: this.getPaymentEventName(paymentCode)
    })
  }

  maxProductCategoryCodeValue(products: ProductStoreInterface): ProductStoreInterfaceSnapshotOut {
    return products.reduce(
      (acc: ProductStoreInterfaceSnapshotOut, current: ProductStoreInterfaceSnapshotOut) => {
        if (acc.price < current.price) {
          return (acc = current)
        } else {
          return acc
        }
      },
      { price: 0, productCategoryCode: '' } as ProductStoreInterfaceSnapshotOut
    )
  }

  gotoMapFromPayment(): void {
    this.logEvent('goto_map_from_payment', {
      userID: this.rootStore.userInfo.id,
      orderId: this.rootStore.header.id
    })
  }

  userPurchase(): void {
    const paymentCode = this.storage.load(LAST_PAYMENT_VENDOR_KEY) as PaymentsMethodAcceptedTypes
    this.logEvent('user_purchase', {
      promocode_ID: this.rootStore.header.couponCode,
      paymentype_ID: this.getPaymentEventName(paymentCode),
      // userType: this.rootStore.userType,
      order_id: this.rootStore.header.id,
      version: VERSION_WEB,
      withvalue: this.rootStore.header.vatIncludedFullTotal,
      currencyCode: this.rootStore.header.currencyCode,
      quantity: this.rootStore.products.reduce((acc: number, current: ProductStoreInterfaceSnapshotOut) => {
        return (acc += current.quantity)
      }, 0),
      main_product_type: this.maxProductCategoryCodeValue(this.rootStore.products).productCode || ''
    })
  }

  addressAddClick(): void {
    this.logEvent('address_add_click', {
      type: ADDRESS_TYPE_SHIPPING
    })
  }

  addressFormSave(): void {
    this.logEvent('addressform_save', {
      type: ADDRESS_TYPE_SHIPPING
    })
  }

  addressFormSearch(): void {
    this.logEvent('addressform_search', {
      type: ADDRESS_TYPE_SHIPPING
    })
  }

  addressFormView(): void {
    this.logEvent('addressform_view', {
      type: ADDRESS_TYPE_SHIPPING
    })
  }

  addressListDeleteClick(): void {
    this.logEvent('addresslist_delete_click', {
      type: ADDRESS_TYPE_SHIPPING
    })
  }

  confirmDeleteAddress(): void {
    this.logEvent('confirm_delete_address', {
      outcome: 'yes'
    })
  }

  addressListEditClick(): void {
    this.logEvent('addresslist_edit_click', {
      type: ADDRESS_TYPE_SHIPPING
    })
  }

  addressListView(): void {
    this.logEvent('addresslist_view', {
      type: ADDRESS_TYPE_SHIPPING
    })
  }

  editOrderAddressTap(): void {
    this.logEvent('edit_order_address_tap', {
      source: this.rootStore.shippingMethod.code
    })
  }
  promoCodeInfoClick({ promoCodeId, reason, reasonText }: Parameters<AmplitudeEventInterface['promoCodeInfoClick']>[0]): void {
    this.logEvent('promo_code_info_click', {
      promocode_ID: promoCodeId,
      reason,
      reason_text: reasonText
    })
  }
  deliveryTypesInfoClick({ type, info, message }: Parameters<AmplitudeEventInterface['deliveryTypesInfoClick']>[0]): void {
    this.logEvent('deliverytypes_info_click', {
      type,
      info,
      message
    })
  }

  confirmOrderError({ error }: Parameters<AmplitudeEventInterface['confirmOrderError']>[0]): void {
    this.logEvent('confirm_order_error', {
      error
    })
  }

  orderDeleteClick(): void {
    this.logEvent('thankyoupage_order_delete_click')
  }
}
